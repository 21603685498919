<template>
  <ToggleButton
    :speed="500"
    class="is-size-7"
    :disabled="loading"
    :value="myStock"
    :labels="toggleConfig.labels"
    :width="toggleConfig.width"
    :height="toggleConfig.height"
    :color="toggleConfig.colors"
    :switch-color="toggleConfig.switchColour"
    :sync="true"
    @change="toggle($event.value)"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { toggleColours } from 'modules/ui/services'

export default {
  name: 'SimilarListingsStockToggle',
  computed: {
    ...mapGetters('companion/similarListings', ['loading']),
    values: {
      get() {
        return this.$store.state.companion.similarListings.filterBy.values
          .listing
      },
      set(values) {
        this.setFilter({ listing: values })
      }
    },
    myStock() {
      return (
        this.values.includes('My stock') &&
        !this.values.includes('Competitor stock')
      )
    },
    toggleConfig() {
      return {
        colors: { ...toggleColours(), disabled: '#b5b5b5' },
        labels: {
          checked: 'My stock',
          unchecked: 'All stock'
        },
        height: 30,
        width: 90
      }
    }
  },
  methods: {
    ...mapActions({
      setFilter: 'companion/similarListings/filterBy/updateFilter'
    }),
    toggle(value) {
      const filteredValues = this.values.filter(
        value => !value.includes('stock')
      )
      if (value) filteredValues.push('My stock')
      this.setFilter({ listing: filteredValues })
    }
  }
}
</script>
